var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pa-sm-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"60px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('span',{staticClass:"text-subtitle-1 text-sm-h6"},[_vm._v("点検結果一覧")])]),_c('v-col',{attrs:{"cols":"5","sm":"4","align-self":"center"}},[_c('MenuMonthPicker',{attrs:{"month":_vm.month},on:{"update":_vm.updateMonth}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('TableSummary',{attrs:{"selected":_vm.selected,"items":_vm.items,"headers":_vm.$vuetify.breakpoint.xs ? _vm.xsHeaders : _vm.headers,"filterHeader":_vm.filterHeader,"comments":[],"loading":_vm.loading,"sortBy":"registeredAt","sortDesc":true,"tableHeight":_vm.getTableHeight},on:{"update:selected":function($event){_vm.selected=$event},"clickRow":function($event){return _vm.$refs.checkResultDialog.openDialog($event)}},scopedSlots:_vm._u([{key:"header.searchTagsJoin",fn:function(){return [_c('v-autocomplete',{staticClass:"text-caption text-sm-body-2",attrs:{"items":_vm.filterItems('searchTagsJoin'),"item-text":"name","return-object":"","placeholder":"検索タグ","multiple":"","no-data-text":"","hide-no-data":"","background-color":"white","append-icon":"mdi-plus-circle-outline","solo":"","flat":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":_vm.isDarkChip(_vm.getColor(item)),"small":"","close":""},on:{"click:close":function($event){return _vm.filterHeader.searchTagsJoin.splice(index, 1)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.filterHeader.searchTagsJoin),callback:function ($$v) {_vm.$set(_vm.filterHeader, "searchTagsJoin", $$v)},expression:"filterHeader.searchTagsJoin"}})]},proxy:true},{key:"item.registeredAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.registeredAt, "M/D (ddd)"))+" "),(item.comment)?_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-comment-processing-outline")]):_vm._e()],1)]}},{key:"item.searchTagsJoin",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[(item.searchTagsJoin == 'タグなし')?_c('span',{staticClass:"grey--text"},[_vm._v("タグなし")]):_vm._l((item.searchTagsJoin.split(',').filter(function (e) { return !!e; })),function(tag,i){return _c('v-chip',{key:(index + "_" + i),attrs:{"color":_vm.getColor(tag),"dark":_vm.isDarkChip(_vm.getColor(tag)),"small":""}},[_vm._v(" "+_vm._s(tag)+" ")])})],2)]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('ButtonApproveResult',{attrs:{"items":_vm.selected,"disabled":_vm.selected.length == 0,"collection":"photoRecordCheckResults"},on:{"reload":function($event){return _vm.loadList()}}})],1),_c('DialogCheckResult',{ref:"checkResultDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }