<template>
  <v-row no-gutters>
    <v-col class="d-flex align-center" cols="12">
      <v-btn icon @click="updateDate(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        left
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-bind="attrs" v-on="on" :value="date" solo flat readonly hide-details />
        </template>
        <v-date-picker
          no-title
          color="primary"
          locale="ja-jp"
          :day-format="(date) => new Date(date).getDate()"
          :max="today.replace(/\//g, '-')"
          :value="date.replace(/\//g, '-')"
          @input="pickDate"
        >
          <v-btn class="pa-0" text color="primary" @click="pickDate(today)">今日</v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn icon :disabled="date == today" @click="updateDate(1)">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: String,
  },
  data: () => ({
    menu: false,
    today: "",
  }),
  created: function () {
    this.today = this.date;
  },
  methods: {
    /**
     * 日時を指定日数分動かす
     * @param {number} value ずらす日数
     */
    updateDate(value) {
      const date = moment(new Date(this.date + " 00:00:00"))
        .add(value, "days")
        .format("YYYY/MM/DD");
      this.$emit("update:date", date);
    },

    /**
     * 日付取得結果から一覧更新するカスタムイベント送信
     * @param {string} value YYYY-MM-DD
     */
    pickDate(value) {
      const date = value.replace(/-/g, "/");
      this.$emit("update:date", date);
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.v-input ::v-deep input {
  text-align: center;
}

::v-deep .v-card__actions {
  justify-content: center;
}
</style>
