<template>
  <v-dialog v-model="dialog" persistent width="400" max-width="500">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">以下の{{ target }}を削除します。</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">
        <p class="mb-0">{{ target }}名：{{ targetName }}</p>
      </v-card-text>
      <v-card-actions class="py-2 px-0 px-sm-2">
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          class="white--text"
          color="#b71c1c"
          depressed
          :loading="processing"
          :disabled="processing"
          @click="deleteItem()"
        >
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "../../plugins/firebase";

export default {
  props: {
    item: Object,
    removeItem: Function,
  },
  data: () => ({
    dialog: false,
    key: "",
    index: null,
    target: "",
    targetName: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開く
     */
    openDialog(type, index) {
      this.index = index;
      this.key = type == "form" ? "formTags" : "searchTags";
      this.target = type == "form" ? "帳票タグ" : "検索タグ";
      this.targetName = this.item[this.key][index].name;
      this.dialog = true;
    },

    /**
     * 情報削除
     */
    async deleteItem() {
      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const registData = {
        shopUID: shop.shopUID,
        formTags: this.item.formTags ? JSON.parse(JSON.stringify(this.item.formTags)) : [],
        searchTags: this.item.searchTags ? JSON.parse(JSON.stringify(this.item.searchTags)) : [],
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };
      registData[this.key].splice(this.index, 1);

      const isSuccess = await this.removeItem({
        docId: this.item.id,
        data: registData,
        target: this.key == "formTags" ? "帳票" : "タグ",
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>
