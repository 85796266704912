<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="text-subtitle-1 text-sm-h6">{{ title }}{{ mode }}</span>
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">
        <v-form v-model="valid" ref="form">
          <template v-if="type == 'form'">
            <v-text-field v-model="name" :rules="[(value) => !!value || '必須入力です']">
              <template slot="label">帳票名<span class="asterisk">*</span></template>
            </v-text-field>
            <v-textarea class="mt-6" v-model="remarks" label="備考" hide-details dense />
          </template>
          <v-row v-else no-gutters>
            <v-col class="pr-sm-1" cols="12" sm="5">
              <v-text-field v-model="name" :rules="[(value) => !!value || '必須入力です']">
                <template slot="label">タグ名<span class="asterisk">*</span></template>
              </v-text-field>
              <v-textarea class="mt-6" v-model="remarks" label="備考" hide-details dense />
            </v-col>
            <v-col class="pt-4 pt-sm-0 pl-sm-1" cols="12" sm="7">
              <div class="pl-1 pb-1">タグの色</div>
              <v-color-picker
                hide-canvas
                hide-inputs
                hide-sliders
                show-swatches
                swatches-max-height="210"
                :value="color"
                @update:color="color = $event.hexa"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="processing || !valid"
          @click="registItem()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase, { db } from "../../plugins/firebase";

export default {
  props: {
    item: Object,
    storeItem: Function,
  },
  data: () => ({
    dialog: false,
    valid: false,
    type: "",
    key: "",
    index: null,
    title: "",
    mode: "",
    name: "",
    remarks: "",
    color: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {string} type
     * @param {object} index
     */
    openDialog(type, index) {
      this.type = type;
      this.index = index;
      this.key = type == "form" ? "formTags" : "searchTags";
      this.title = type == "form" ? "帳票タグ" : "検索タグ";
      const selectItem = index == undefined ? null : this.item[this.key][index];
      this.mode = !selectItem ? "登録" : "編集";
      this.name = !selectItem ? "" : selectItem.name;
      this.remarks = !selectItem ? "" : selectItem.remarks;
      this.color = !selectItem ? "" : selectItem.color;
      this.dialog = true;
      if (selectItem == undefined) this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 入力情報をDB登録
     */
    async registItem() {
      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.item.id;
      const shouldAdd = this.index === undefined;

      const registId = this.item.id || db.collection("photoRecordTags").doc().id;
      const registData = {
        shopUID: shop.shopUID,
        formTags: this.item.formTags ? JSON.parse(JSON.stringify(this.item.formTags)) : [],
        searchTags: this.item.searchTags ? JSON.parse(JSON.stringify(this.item.searchTags)) : [],
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };

      const editedData = {
        name: this.name,
        remarks: this.remarks || "",
      };
      if (this.type == "search")
        editedData.color = this.color == "#FF0000FF" ? "#E0E0E0FF" : this.color;

      if (shouldAdd) registData[this.key].push(editedData);
      if (!shouldAdd) registData[this.key][this.index] = editedData;

      if (isCreate) registData.createdAt = serverTimestamp;

      const isSuccess = await this.storeItem({
        isCreate,
        docId: registId,
        data: registData,
        target: this.key == "formTags" ? "帳票" : "タグ",
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-color-picker__swatches div {
  justify-content: start;
  padding: 0 !important;
}
</style>
