<template>
  <div class="fill-height d-flex flex-column" style="width: 100%">
    <v-sheet
      ref="sheet"
      :style="coveronly ? 'height: 100%' : 'height: calc(100% - 40px)'"
      rounded
      :outlined="outlined"
      @click="$emit('click')"
    >
      <div v-if="coveronly" class="fill-height d-flex justify-center align-center">
        <pdf :src="loading ? '' : loadingTask" :page="1" :style="{ width: width }" />
      </div>
      <v-carousel
        v-else
        v-model="currentPage"
        hide-delimiters
        :show-arrows="!loading && pageCount > 1"
        height="100%"
      >
        <v-carousel-item v-for="page in pageCount" :key="page">
          <div class="fill-height d-flex justify-center align-center">
            <pdf
              :src="loading ? '' : loadingTask"
              :page="currentPage + 1"
              :style="{ width: width }"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
    <div v-if="!coveronly" class="py-2 text-center" style="height: 40px">
      <span v-show="!loading">{{ currentPage + 1 }} / {{ pageCount }}</span>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: {
    src: String,
    outlined: Boolean,
    coveronly: Boolean,
  },
  components: {
    pdf,
  },
  data: () => ({
    pageCount: 1,
    currentPage: 0,
    loading: true,
    loadingTask: {},
    viewport: {},
    width: "0px",
  }),
  mounted: function () {
    this.loadPDF();
    window.addEventListener("resize", this.setPDFSize, { passive: true });
  },
  watch: {
    src() {
      this.loadPDF();
    },
  },
  methods: {
    /**
     * PDF情報の読み込み
     */
    async loadPDF() {
      this.loading = true;
      this.currentPage = 0;

      this.loadingTask = await pdf.createLoadingTask(this.src);
      const task = await this.loadingTask.promise;
      this.pageCount = task.numPages;
      const pageData = await task.getPage(1);
      this.viewport = pageData.getViewport({ scale: 1 });
      this.setPDFSize();

      this.loading = false;
    },

    /**
     * PDF表示幅の設定
     */
    async setPDFSize() {
      await this.$nextTick();
      const maxWidth = this.$refs.sheet.$el.clientWidth;
      const maxHeight = this.$refs.sheet.$el.clientHeight;
      const aspect = this.viewport.width / this.viewport.height;

      // 横長PDFの場合
      if (aspect > 1) {
        const width = this.viewport.width > maxWidth ? maxWidth : this.viewport.width;
        const height = width / aspect;
        this.width = `${height > maxHeight ? maxHeight * aspect : width}px`;
      }

      // 縦長PDFの場合
      if (aspect <= 1) {
        const height = this.viewport.height > maxHeight ? maxHeight : this.viewport.height;
        const width = height * aspect;
        this.width = `${width > maxWidth ? maxWidth : width}px`;
      }
    },
  },
};
</script>
