var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"#f8f8f8"}},[_c('div',{staticClass:"pl-4 pr-4 pr-sm-0 d-flex align-center",staticStyle:{"min-height":"48px"}},[(_vm.showCount)?_c('div',{staticClass:"d-flex align-baseline text-body-2"},[_c('div',{staticClass:"d-flex align-baseline",staticStyle:{"width":"100px"}},[_c('span',[_vm._v("表示数：")]),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$refs.table.selectableItems.length))])]),_c('span',[_vm._v("未承認：")]),(!_vm.$refs.table.selectableItems.some(function (e) { return e.approverName == ''; }))?_c('span',[_vm._v("なし")]):_c('span',{staticClass:"text-h5 red--text"},[_vm._v(_vm._s(_vm.$refs.table.selectableItems.filter(function (e) { return e.approverName == ""; }).length))])]):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.filterCheckBoxes),function(box){return _c('v-checkbox',{key:box.label,staticClass:"ma-0 mr-4",attrs:{"hide-details":"","ripple":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ml-1 text-caption text--primary"},[_vm._v(_vm._s(box.label))])]},proxy:true}],null,true),model:{value:(box.value),callback:function ($$v) {_vm.$set(box, "value", $$v)},expression:"box.value"}})}),1):_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.filterCheckBoxes.some(function (e) { return !!e.value; }) ? "mdi-filter" : "mdi-filter-outline"))])],1)]}}])},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.filterCheckBoxes),function(box){return _c('v-list-item',{key:box.label,on:{"click":function($event){box.value = !box.value}}},[_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"value":box.value,"hide-details":"","ripple":false}})],1),_c('v-list-item-content',{staticClass:"pr-2"},[_c('v-list-item-title',[_vm._v(_vm._s(box.label))])],1)],1)}),1)],1)],1),_c('v-data-table',{ref:"table",class:_vm.$vuetify.breakpoint.xs ? 'table--xs' : '',attrs:{"value":_vm.selected,"headers":_vm.customHeaders,"items":_vm.items,"items-per-page":-1,"loading":_vm.loading,"loading-text":"読込中","no-data-text":"点検結果がありません","no-results-text":"点検結果が見つかりません","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-sort":_vm.$vuetify.breakpoint.xs,"show-select":_vm.showSelect,"multi-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":!!_vm.tableHeight ? _vm.tableHeight : _vm.getTableHeight,"dense":""},on:{"input":function($event){return _vm.$emit('update:selected', $event)}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return [(!!_vm.selected.length && _vm.selected.length < _vm.items.length)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectAll($event)}}},[_vm._v(" mdi-minus-box ")]):_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":_vm.selected.length > 0 && _vm.selected.length == _vm.items.length},on:{"input":function($event){return _vm.selectAll($event)}}})]}},_vm._l((_vm.getUniqueHeaders),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_vm._t(("header." + (h.value)),function(){return [(_vm.filterHeader[h.value] !== undefined)?_c('v-combobox',{staticClass:"text-caption text-sm-body-2",attrs:{"items":_vm.filterItems(h.value),"clearable":"","placeholder":h.text,"background-color":_vm.filterHeader[h.value] ? 'tertiary' : 'white',"solo":"","flat":"","hide-details":"","dense":""},model:{value:(_vm.filterHeader[h.value]),callback:function ($$v) {_vm.$set(_vm.filterHeader, h.value, $$v)},expression:"filterHeader[h.value]"}}):_c('span',{key:h.text},[_vm._v(_vm._s(h.text))])]},{"header":header,"index":_vm.headers.findIndex(function (e) { return e.value == h.value; })})]}}}),{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tr',{on:{"click":function($event){return _vm.$emit('clickRow', item)}}},[(_vm.showSelect)?_c('td',{attrs:{"id":("td-checkbox-" + (item.id))}},[(_vm.isDisabled(item))?_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-checkbox-blank")]):_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})],1):_vm._e(),_vm._l((_vm.getUniqueHeaders),function(h,i){return _vm._t(("item." + (h.value)),function(){return [_c('td',{key:i,class:_vm.filterHeader[h.value] !== undefined ? 'pl-3 pl-sm-4' : ''},[_vm._v(" "+_vm._s(item[h.value])+" ")])]},{"item":item,"index":index})}),(_vm.headers.some(function (h) { return h.value == 'isNormalForReport'; }))?_c('td',[_c('span',{class:item.isNormalForReport ? '' : 'red--text font-weight-bold'},[_vm._v(" "+_vm._s(item.isNormalForReport ? "OK" : "NG")+" ")]),(
              _vm.comments.some(function (e) { return e.relationCheckResult && e.relationCheckResult.uid == item.id; })
            )?_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-comment-processing-outline ")]):_vm._e()],1):_vm._e(),(_vm.headers.some(function (h) { return h.value == 'answeredAt'; }))?_c('td',[_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.answeredAt, "MM/DD HH:mm")))])]):_vm._e(),(_vm.headers.some(function (h) { return h.value == 'sentAt'; }))?_c('td',[_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.sentAt, "MM/DD HH:mm")))]),(_vm.$vuetify.breakpoint.smAndUp)?_c('p',{staticClass:"grey--text",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sender)+" ")]):_vm._e()]):_vm._e(),(_vm.headers.some(function (h) { return h.value == 'confirmedAt'; }))?_c('td',{staticClass:"text-center text-sm-start"},[(_vm.$vuetify.breakpoint.smAndUp)?[_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.confirmedAt, "MM/DD HH:mm")))]),_c('p',{staticClass:"grey--text",staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.confirmerName))])]:[_vm._v(_vm._s(item.confirmerName ? "〇" : ""))]],2):_vm._e(),(_vm.headers.some(function (h) { return h.value == 'approvedAt'; }))?_c('td',{staticClass:"text-center text-sm-start"},[(_vm.$vuetify.breakpoint.smAndUp)?[_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.approvedAt, "MM/DD HH:mm")))]),_c('p',{staticClass:"grey--text",staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.approverName))])]:[_vm._v(_vm._s(item.approverName ? "〇" : ""))]],2):_vm._e(),(_vm.headers.some(function (h) { return h.value == 'location'; }))?_c('td',[(item.location)?_c('v-btn',{attrs:{"icon":"","href":("https://www.google.co.jp/maps/place/" + (item.location)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-map-search")])],1):_vm._e()],1):_vm._e()],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }