<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">設定</span>
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="mb-0" v-if="updaterName">更新者：{{ updaterName }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pb-1 pb-sm-0 pr-sm-1" cols="12" sm="6">
        <v-card color="#f8f8f8">
          <v-card-title class="text-body-1">
            帳票一覧
            <v-spacer></v-spacer>
            <v-btn
              class="white"
              color="primary"
              icon
              elevation="2"
              @click.stop="$refs.editDialog.openDialog('form')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="formHeaders"
            :items="item.formTags"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            :height="getTableHeight"
            dense
          >
            <template v-slot:[`item.action`]="{ index }">
              <v-btn icon @click.stop="$refs.editDialog.openDialog('form', index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="$refs.deleteDialog.openDialog('form', index)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="pt-1 pt-sm-0 pl-sm-1" cols="12" sm="6">
        <v-card color="#f8f8f8">
          <v-card-title class="text-body-1">
            検索タグ一覧
            <v-spacer></v-spacer>
            <v-btn
              class="white"
              color="primary"
              icon
              elevation="2"
              @click.stop="$refs.editDialog.openDialog('search')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="searchHeaders"
            :items="item.searchTags"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            :height="getTableHeight"
            dense
          >
            <template v-slot:[`item.color`]="{ item }">
              <v-icon :color="item.color ? item.color : '#E0E0E0FF'">mdi-square</v-icon>
            </template>
            <template v-slot:[`item.action`]="{ index }">
              <v-btn icon @click.stop="$refs.editDialog.openDialog('search', index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="$refs.deleteDialog.openDialog('search', index)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <DialogDocEdit ref="editDialog" :item="item" :storeItem="storeItem" />
    <DialogDocDelete ref="deleteDialog" :item="item" :removeItem="removeItem" />
    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import moment from "moment";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogDocEdit from "../organisms/DialogDocEdit.vue";
import DialogDocDelete from "../organisms/DialogDocDelete.vue";

export default {
  components: {
    DialogDocEdit,
    DialogDocDelete,
  },
  mixins: [dbProcess],
  data: () => ({
    loading: false,
    updatedAt: "",
    updaterName: "",
    item: {},
    formHeaders: [
      { text: "帳票名", value: "name", width: "40%" },
      { text: "備考", value: "remarks", width: "60%" },
      { text: "操作", value: "action", sortable: false, align: "center", width: 104 },
    ],
    searchHeaders: [
      { text: "タグ名", value: "name", width: "40%" },
      { text: "色", value: "color", sortable: false, align: "center", width: 56 },
      { text: "備考", value: "remarks", width: "60%" },
      { text: "操作", value: "action", sortable: false, align: "center", width: 104 },
    ],
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
  },
  activated: function () {
    this.loadList();
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 264 : 256);
      return height <= 500 ? 500 : height;
    },
  },
  methods: {
    /**
     * DBからタグ一覧を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const items = await this.getQueryDoc({
        collection: "photoRecordTags",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "createdAt", directionStr: "asc" }],
      });
      const item = items[0];
      this.item = item
        ? { id: item.id, formTags: item.formTags || [], searchTags: item.searchTags || [] }
        : { id: "", formTags: [], searchTags: [] };

      this.updatedAt = item ? moment(item.updatedAt.seconds * 1000).format("YYYY/MM/DD") : "";
      this.updaterName = item ? item.updaterName : "";
      this.loading = false;
    },

    /**
     * 記入情報をDBに登録
     * @param {boolean} isCreate 新規作成かどうか
     * @param {string} docId uid
     * @param {object} data 登録情報
     * @param {string} target
     * @return {number} 成功したか
     */
    async storeItem({ isCreate, docId, data, target }) {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return false;
      }

      const result = await this.writeTransaction([
        {
          method: isCreate ? "set" : "update",
          collection: "photoRecordTags",
          docId,
          data,
        },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return false;
      }

      this.loadList();
      this.message = isCreate ? `${target}を登録しました。` : `${target}情報を更新しました。`;
      this.messageDialog = true;
      return true;
    },

    /**
     * 選択したタグをDBから削除
     * @param {string} docId uid
     * @param {object} data 登録情報
     * @param {string} target
     */
    async removeItem({ docId, data, target }) {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return false;
      }

      const result = await this.writeTransaction([
        { method: "update", collection: "photoRecordTags", docId, data },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return false;
      }

      this.loadList();
      this.message = `${target}を削除しました。`;
      this.messageDialog = true;
      return true;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
}

::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}
.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
}
</style>
