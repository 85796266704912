<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">点検結果詳細</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- 詳細内容 -->
      <CheckResultDetail :result="item" />
    </v-card>
  </v-dialog>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckResultDetail from "../organisms/CheckResultDetail";

export default {
  components: {
    CheckResultDetail,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    item: {},
    width: null,
    height: null,
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した点検結果
     */
    async openDialog(selectedItem) {
      this.item = selectedItem;
      this.dialog = true;
    },
  },
};
</script>
