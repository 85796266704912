<template>
  <div>
    <div class="px-4 pb-4 text-caption text-sm-body-2">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">帳票名：</span>
            <span>{{ result.formTagName }}</span>
          </p>
          <p class="d-flex flex-wrap align-center">
            <span class="text-no-wrap">検索タグ：</span>
            <v-chip
              v-for="(tag, i) in result.searchTags"
              :key="i"
              :color="tag.color"
              :dark="isDarkChip(tag.color)"
              small
            >
              {{ tag.name }}
            </v-chip>
            <span v-if="result.searchTags.length == 0">なし</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">特記事項：</span>
            <span>{{ result.comment }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">送信日時：</span>
        <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4 text-caption text-sm-body-2">点検画像</p>
    <template v-if="result.imgFileURL">
      <v-sheet
        v-if="result.imgFileURL.includes('.pdf?')"
        class="transparent px-2"
        :height="preview ? '800px' : '50vh'"
      >
        <PDFView
          :src="result.imgFileURL"
          outlined
          @click="
            $refs.dialogImg.openDialog({
              text: result.formTagName,
              url: result.imgFileURL,
              fileName: `${formatDate(result.registeredAt, 'YYYYMMDD')}_${result.id}.pdf`,
            })
          "
        />
      </v-sheet>
      <v-card
        v-else
        class="px-4 py-2 d-flex justify-center align-center"
        outlined
        :height="preview ? '800px' : '50vh'"
      >
        <ImgView
          :src="result.imgFileURL"
          @click="
            $refs.dialogImg.openDialog({
              text: result.formTagName,
              url: result.imgFileURL,
              fileName: `${formatDate(result.registeredAt, 'YYYYMMDD')}_${result.id}.jpeg`,
            })
          "
        />
      </v-card>
    </template>

    <template v-if="result.imgFiles">
      <v-card class="pa-2" outlined :height="preview ? '800px' : '50vh'">
        <v-carousel
          v-model="showIndex"
          hide-delimiters
          :show-arrows="result.imgFiles.length > 1"
          height="100%"
        >
          <v-carousel-item v-for="(file, i) in result.imgFiles" :key="i">
            <v-sheet
              v-if="file.url.startsWith('data:application/pdf') || file.url.includes('.pdf?')"
              height="100%"
              @click="$refs.dialogImg.openDialog({ text: file.fileName, url: file.url })"
            >
              <PDFView :src="file.url" coveronly />
            </v-sheet>
            <ImgView
              v-else
              :src="file.url"
              @click="$refs.dialogImg.openDialog({ text: file.fileName, url: file.url })"
            />
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <p class="my-2 text-center">{{ `${showIndex + 1} / ${result.imgFiles.length}` }}</p>
    </template>

    <DialogImg ref="dialogImg" />
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import PDFView from "../organisms/PDFView";

export default {
  components: {
    PDFView,
  },
  props: {
    result: Object,
    preview: Boolean,
  },
  mixins: [calcDate],
  data: () => ({
    showIndex: 0,
  }),
  computed: {
    /**
     * チップの文字色を背景色から計算
     * @param {string} 色（例：#4A148CFF）
     */
    isDarkChip() {
      return (color) => {
        if (!color) return false;
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
        const a = parseInt(color.substr(7, 2), 16);
        if (a == 0) return false;
        return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? true : false;
      };
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}
</style>
