<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="5" sm="4" align-self="center">
        <MenuMonthPicker :month="month" @update="updateMonth" />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <TableSummary
          :selected.sync="selected"
          :items="items"
          :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
          :filterHeader="filterHeader"
          :comments="[]"
          :loading="loading"
          sortBy="registeredAt"
          :sortDesc="true"
          :tableHeight="getTableHeight"
          @clickRow="$refs.checkResultDialog.openDialog($event)"
        >
          <template v-slot:[`header.searchTagsJoin`]>
            <v-autocomplete
              class="text-caption text-sm-body-2"
              v-model="filterHeader.searchTagsJoin"
              :items="filterItems('searchTagsJoin')"
              item-text="name"
              return-object
              placeholder="検索タグ"
              multiple
              no-data-text=""
              hide-no-data
              background-color="white"
              append-icon="mdi-plus-circle-outline"
              solo
              flat
              hide-details
              dense
            >
              <template #selection="{ item, index }">
                <v-chip
                  :color="getColor(item)"
                  :dark="isDarkChip(getColor(item))"
                  small
                  close
                  @click:close="filterHeader.searchTagsJoin.splice(index, 1)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </template>

          <template v-slot:[`item.registeredAt`]="{ item }">
            <td>
              {{ formatDate(item.registeredAt, "M/D (ddd)") }}
              <v-icon v-if="item.comment" class="ml-1">mdi-comment-processing-outline</v-icon>
            </td>
          </template>
          <template v-slot:[`item.searchTagsJoin`]="{ item, index }">
            <td>
              <span v-if="item.searchTagsJoin == 'タグなし'" class="grey--text">タグなし</span>
              <template v-else>
                <v-chip
                  v-for="(tag, i) in item.searchTagsJoin.split(',').filter((e) => !!e)"
                  :key="`${index}_${i}`"
                  :color="getColor(tag)"
                  :dark="isDarkChip(getColor(tag))"
                  small
                >
                  {{ tag }}
                </v-chip>
              </template>
            </td>
          </template>
        </TableSummary>
      </v-col>
    </v-row>

    <!-- 承認ボタン -->
    <v-row no-gutters justify="end">
      <ButtonApproveResult
        :items="selected"
        :disabled="selected.length == 0"
        collection="photoRecordCheckResults"
        @reload="loadList()"
      />
    </v-row>

    <!-- 結果詳細ダイアログ -->
    <DialogCheckResult ref="checkResultDialog" />
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import TableSummary from "../organisms/TableSummary";
import DialogCheckResult from "../organisms/DialogCheckResult";

export default {
  components: {
    TableSummary,
    DialogCheckResult,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    month: "",
    searchTags: [],
    selected: [],
    items: [],
    headers: [
      { text: "点検日", value: "registeredAt", width: 108 },
      { text: "帳票名", value: "formTagName", sortable: false, width: 150, class: "responsive" },
      {
        text: "検索タグ",
        value: "searchTagsJoin",
        sortable: false,
        width: 150,
        class: "responsive",
      },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "確認日時", value: "confirmedAt", width: 100 },
      { text: "承認日時", value: "approvedAt", width: 112 },
    ],
    xsHeaders: [
      { text: "点検日", value: "registeredAt", width: 92 },
      { text: "帳票", value: "formTagName", width: 120, class: "responsive" },
      { text: "タグ", value: "searchTagsJoin", width: 120, class: "responsive" },
      { text: "送信", value: "sentAt", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    filterHeader: { formTagName: "", searchTagsJoin: [] },
  }),
  created: function () {
    this.$emit("created");
    this.month = moment().format("YYYY/MM");
  },
  activated: function () {
    this.loadList();
  },
  computed: {
    /**
     * データテーブルの高さ取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 246 : 296);
      return height <= 300 ? 300 : height;
    },

    /**
     * チップの背景色を取得
     * @param {string} 色（例：#4A148CFF）
     */
    getColor() {
      return (tagName) => {
        const tag = this.searchTags.find((e) => e.name == tagName);
        return tag ? tag.color : "";
      };
    },

    /**
     * チップの文字色を背景色から計算
     * @param {string} 色（例：#4A148CFF）
     */
    isDarkChip() {
      return function (color) {
        if (!color) return false;
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
        const a = parseInt(color.substr(7, 2), 16);
        if (a == 0) return false;
        return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? true : false;
      };
    },

    /**
     * フィルター用のリスト作成
     * @return {string} key
     * @return {array}
     */
    filterItems() {
      return (key) => {
        const items = [...new Set(this.items.map((e) => e[key]))].join().split(",");
        items.sort();
        return items;
      };
    },
  },
  methods: {
    /**
     * 回答リストを取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.month + "/01 00:00:00");
      const endAt = new Date(moment(startAt).add(1, "month").add(-1, "seconds"));

      const results = await this.getQueryDoc({
        collection: "photoRecordCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt,
        endAt,
      });

      // 帳票名でソート
      results.sort((a, b) => {
        if (a.formTagName < b.formTagName) return -1;
        if (a.formTagName > b.formTagName) return 1;
        return 0;
      });

      this.items = results.map((e) => ({
        ...e,
        searchTagsJoin:
          e.searchTags.length > 0 ? e.searchTags.map((t) => t.name).join(",") : "タグなし",
      }));

      const tags = await this.getQueryDoc({
        collection: "photoRecordTags",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "createdAt", directionStr: "asc" }],
      });
      if (tags.length > 0) this.searchTags = tags[0].searchTags ? tags[0].searchTags : [];

      this.loading = false;
    },

    /**
     * 月変更があったら点検結果再読み込み
     * @param {string} month YYYY/MM
     */
    async updateMonth(month) {
      this.month = month;
      await this.loadList();
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
