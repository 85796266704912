<template>
  <v-card color="#f8f8f8">
    <!-- 表示数・NGフィルター -->
    <div class="pl-4 pr-4 pr-sm-0 d-flex align-center" style="min-height: 48px">
      <div v-if="showCount" class="d-flex align-baseline text-body-2">
        <div class="d-flex align-baseline" style="width: 100px">
          <span>表示数：</span>
          <span class="text-h5">{{ $refs.table.selectableItems.length }}</span>
        </div>
        <span>未承認：</span>
        <span v-if="!$refs.table.selectableItems.some((e) => e.approverName == '')">なし</span>
        <span v-else class="text-h5 red--text">{{
          $refs.table.selectableItems.filter((e) => e.approverName == "").length
        }}</span>
      </div>
      <v-spacer></v-spacer>

      <!-- 横スマホ・タブレット・PC -->
      <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex">
        <v-checkbox
          class="ma-0 mr-4"
          v-for="box in filterCheckBoxes"
          :key="box.label"
          v-model="box.value"
          hide-details
          :ripple="false"
        >
          <template v-slot:label>
            <span class="ml-1 text-caption text--primary">{{ box.label }}</span>
          </template>
        </v-checkbox>
      </div>

      <!-- 縦スマホ -->
      <v-menu v-else :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>{{
              filterCheckBoxes.some((e) => !!e.value) ? "mdi-filter" : "mdi-filter-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <v-list class="py-0" dense>
          <v-list-item
            v-for="box in filterCheckBoxes"
            :key="box.label"
            @click="box.value = !box.value"
          >
            <v-list-item-action class="mr-2">
              <v-checkbox :value="box.value" hide-details :ripple="false" />
            </v-list-item-action>
            <v-list-item-content class="pr-2">
              <v-list-item-title>{{ box.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- テーブル表示 -->
    <v-data-table
      :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
      ref="table"
      :value="selected"
      :headers="customHeaders"
      :items="items"
      :items-per-page="-1"
      :loading="loading"
      loading-text="読込中"
      no-data-text="点検結果がありません"
      no-results-text="点検結果が見つかりません"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :disable-sort="$vuetify.breakpoint.xs"
      :show-select="showSelect"
      multi-sort
      fixed-header
      hide-default-footer
      :mobile-breakpoint="null"
      :height="!!tableHeight ? tableHeight : getTableHeight"
      dense
      @input="$emit('update:selected', $event)"
    >
      <!-- すべて選択するチェックボックス -->
      <template #[`header.data-table-select`]="{}">
        <v-icon
          v-if="!!selected.length && selected.length < items.length"
          color="primary"
          @click="selectAll($event)"
        >
          mdi-minus-box
        </v-icon>
        <v-simple-checkbox
          v-else
          color="primary"
          :ripple="false"
          :value="selected.length > 0 && selected.length == items.length"
          @input="selectAll($event)"
        />
      </template>

      <!-- 親コンポーネントで指定したヘッダー -->
      <template #[`header.${h.value}`]="{ header }" v-for="h in getUniqueHeaders">
        <slot
          :name="`header.${h.value}`"
          :header="header"
          :index="headers.findIndex((e) => e.value == h.value)"
        >
          <!-- フィルター機能 -->
          <v-combobox
            v-if="filterHeader[h.value] !== undefined"
            class="text-caption text-sm-body-2"
            v-model="filterHeader[h.value]"
            :items="filterItems(h.value)"
            clearable
            :placeholder="h.text"
            :background-color="filterHeader[h.value] ? 'tertiary' : 'white'"
            solo
            flat
            hide-details
            dense
          />

          <!-- デフォルト -->
          <span v-else :key="h.text">{{ h.text }}</span>
        </slot>
      </template>

      <template #item="{ item, index, isSelected, select }">
        <tr @click="$emit('clickRow', item)">
          <!-- チェックボックス -->
          <td v-if="showSelect" :id="`td-checkbox-${item.id}`">
            <v-icon v-if="isDisabled(item)" color="grey lighten-2">mdi-checkbox-blank</v-icon>
            <v-simple-checkbox
              v-else
              color="primary"
              :ripple="false"
              :value="isSelected"
              @input="select($event)"
            />
          </td>

          <!-- 親コンポーネントで指定したデータ -->
          <slot
            v-for="(h, i) in getUniqueHeaders"
            :name="`item.${h.value}`"
            :item="item"
            :index="index"
          >
            <td :key="i" :class="filterHeader[h.value] !== undefined ? 'pl-3 pl-sm-4' : ''">
              {{ item[h.value] }}
            </td>
          </slot>

          <!-- 日報用判定結果 -->
          <td v-if="headers.some((h) => h.value == 'isNormalForReport')">
            <span :class="item.isNormalForReport ? '' : 'red--text font-weight-bold'">
              {{ item.isNormalForReport ? "OK" : "NG" }}
            </span>
            <v-icon
              v-if="
                comments.some((e) => e.relationCheckResult && e.relationCheckResult.uid == item.id)
              "
              class="ml-1"
            >
              mdi-comment-processing-outline
            </v-icon>
          </td>

          <!-- 送信日時（個人衛生） -->
          <td v-if="headers.some((h) => h.value == 'answeredAt')">
            <p class="text-caption">{{ formatDate(item.answeredAt, "MM/DD HH:mm") }}</p>
          </td>

          <!-- 送信日時 -->
          <td v-if="headers.some((h) => h.value == 'sentAt')">
            <p class="text-caption">{{ formatDate(item.sentAt, "MM/DD HH:mm") }}</p>
            <p class="grey--text" v-if="$vuetify.breakpoint.smAndUp" style="font-size: 11px">
              {{ item.sender }}
            </p>
          </td>

          <!-- 確認日時 -->
          <td
            v-if="headers.some((h) => h.value == 'confirmedAt')"
            class="text-center text-sm-start"
          >
            <template v-if="$vuetify.breakpoint.smAndUp">
              <p class="text-caption">{{ formatDate(item.confirmedAt, "MM/DD HH:mm") }}</p>
              <p class="grey--text" style="font-size: 11px">{{ item.confirmerName }}</p>
            </template>
            <template v-else>{{ item.confirmerName ? "〇" : "" }}</template>
          </td>

          <!-- 承認日時 -->
          <td v-if="headers.some((h) => h.value == 'approvedAt')" class="text-center text-sm-start">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <p class="text-caption">{{ formatDate(item.approvedAt, "MM/DD HH:mm") }}</p>
              <p class="grey--text" style="font-size: 11px">{{ item.approverName }}</p>
            </template>
            <template v-else>{{ item.approverName ? "〇" : "" }}</template>
          </td>

          <!-- 位置情報 -->
          <td v-if="headers.some((h) => h.value == 'location')">
            <v-btn
              v-if="item.location"
              icon
              :href="`https://www.google.co.jp/maps/place/${item.location}`"
              target="_blank"
              @click.stop
            >
              <v-icon>mdi-map-search</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  mixins: [calcDate],
  props: {
    selected: Array,
    items: Array,
    headers: Array,
    filterHeader: Object,
    comments: Array,
    loading: Boolean,
    sortBy: [String, Array],
    sortDesc: [Boolean, Array],
    tableHeight: Number,
  },
  data: () => ({
    showCount: false,
    showSelect: false,
    filterCheckBoxes: [
      { label: "点検NG", value: false },
      { label: "未確認", value: false },
      { label: "未承認", value: false },
    ],
  }),
  created: function () {
    const user = this.$store.getters.getUser;
    this.showSelect = user.authority == "approver" || user.authority == "confirmer";
  },
  mounted: function () {
    this.showCount = true;
  },
  computed: {
    /**
     * データテーブルの高さ取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.comments.length > 0 ? 186 : 60;
      const height = bp.height - (bp.xs ? 278 : 252) - offset;
      return height <= 300 ? 300 : height;
    },

    /**
     * 個別カラム情報を抽出
     * @return {Array}
     */
    getUniqueHeaders() {
      const commonHeaders = [
        "isNormalForReport",
        "answeredAt",
        "sentAt",
        "confirmedAt",
        "approvedAt",
        "location",
      ];
      return this.headers.filter((h) => !commonHeaders.includes(h.value));
    },

    /**
     * ヘッダーにフィルター処理を付与
     * @return {Array}
     */
    customHeaders() {
      return this.headers.map((header) => {
        header.filter = (value, _, item) => {
          if (this.filterCheckBoxes[0].value && !!item.isNormalForReport) return false;
          if (this.filterCheckBoxes[1].value && item.confirmerName != "") return false;
          if (this.filterCheckBoxes[2].value && item.approverName != "") return false;
          if (!this.filterHeader[header.value]) return true;
          return Array.isArray(this.filterHeader[header.value])
            ? this.filterHeader[header.value].every((e) => value.toString().includes(e))
            : value.toString().includes(this.filterHeader[header.value]);
        };
        return { ...header };
      });
    },

    /**
     * フィルター用のリスト作成
     * @return {string} key
     * @return {array}
     */
    filterItems() {
      return (key) => {
        const items = [...new Set(this.items.map((e) => e[key]))];
        items.sort();
        return items;
      };
    },

    /**
     * チェックボックスの非活性判定
     * @param {object} item 行情報
     * @return {boolean} 判定結果
     */
    isDisabled() {
      return (item) => {
        const user = this.$store.getters.getUser;
        if (user.authority == "confirmer" && item.confirmerName != "") return true;
        if (user.authority == "approver" && item.approverName != "") return true;
        return false;
      };
    },
  },
  methods: {
    /**
     * すべてチェック
     */
    selectAll() {
      const filteredItems = this.$refs.table.selectableItems.filter(
        (item) => !this.isDisabled(item)
      );
      const isSelect = filteredItems.every((e) => this.selected.some((s) => s.id == e.id));
      for (const item of filteredItems) {
        const index = this.selected.findIndex((e) => e.id == item.id);
        if (index != -1 && isSelect) this.selected.splice(index, 1);
        if (index == -1 && !isSelect) this.selected.push(item);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
}

::v-deep .v-autocomplete .v-input__control .v-input__slot {
  padding: 0 0 0 8px !important;
}

::v-deep .v-input__append-inner {
  padding-left: 0 !important;
}

::v-deep .v-list-item:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table .v-data-table-header tr .responsive {
  width: initial !important;
  max-width: initial !important;
}

::v-deep .v-data-table__wrapper table .v-data-table-header tr th {
  padding: 0 8px;
  height: 48px !important;
  background-color: #f8f8f8 !important;
  white-space: pre-wrap;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
  height: 42px !important;
}

.table--xs ::v-deep .v-data-table__wrapper table thead tr th,
.table--xs ::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 4px;
  font-size: 12px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}
</style>
