<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">写真点検票</span>
      </v-col>
    </v-row>

    <v-card class="mb-4 pa-4" color="#f8f8f8">
      <v-row no-gutters>
        <!-- 画像読み込み後 -->
        <v-col v-if="imgFiles.length > 0" class="pb-2 pb-sm-0 pr-sm-2" cols="12" sm="6">
          <v-sheet
            class="d-flex justify-center align-center"
            rounded
            min-height="300"
            style="height: calc(100% - 40px); position: relative"
          >
            <v-btn
              class="icon-camera primary--text"
              width="80"
              height="50"
              color="white"
              @click="$refs.fileInput.click()"
            >
              <v-icon size="30">mdi-camera</v-icon>
              <v-icon size="30">mdi-paperclip</v-icon>
            </v-btn>
            <v-btn
              class="icon-trash text-secondary"
              width="40"
              height="50"
              color="white"
              :ripple="false"
              @click="imgFiles.splice(showIndex, 1)"
            >
              <v-icon size="30">mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-carousel
              v-model="showIndex"
              hide-delimiters
              :show-arrows="imgFiles.length > 1"
              height="100%"
            >
              <v-carousel-item v-for="(file, i) in imgFiles" :key="i">
                <v-sheet
                  v-if="file.url.startsWith('data:application/pdf') || file.url.includes('.pdf?')"
                  height="100%"
                  @click="$refs.dialogImg.openDialog({ text: file.fileName, url: file.url })"
                >
                  <PDFView :src="file.url" coveronly />
                </v-sheet>
                <ImgView
                  v-else
                  :src="file.url"
                  @click="$refs.dialogImg.openDialog({ text: file.fileName, url: file.url })"
                />
                <v-sheet class="fileName px-1 text-caption">{{ file.fileName }}</v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
          <p class="my-2 text-center">{{ `${showIndex + 1} / ${imgFiles.length}` }}</p>
        </v-col>

        <!-- 画像読み込み前 -->
        <v-col
          v-else
          class="pb-2 pb-sm-0 pr-sm-2 d-flex justify-center align-center"
          cols="12"
          sm="6"
        >
          <v-btn text height="70" color="primary" @click.stop="$refs.fileInput.click()">
            <v-icon size="60">mdi-camera</v-icon>
            <v-icon size="60">mdi-paperclip</v-icon>
          </v-btn>
        </v-col>
        <input
          class="d-none"
          ref="fileInput"
          type="file"
          accept="image/jpeg, image/jpg, image/png, application/pdf"
          @input="selectImg"
        />

        <!-- 帳票情報入力 -->
        <v-col class="pt-2 pt-sm-0 pl-sm-2" cols="12" sm="6">
          <!-- 帳票名 -->
          <div class="px-0 px-sm-4 d-flex align-center">
            <div v-if="!$vuetify.breakpoint.xs" class="d-flex align-center" style="width: 100px">
              <span class="mr-1">帳票名</span>
              <ChipRequired />
            </div>
            <v-autocomplete
              v-model="formTagName"
              :items="formTags"
              item-text="name"
              item-value="name"
              :return-object="false"
              :label="$vuetify.breakpoint.xs ? '帳票名' : ''"
              :menu-props="{
                offsetY: true,
                transition: 'slide-y-transition',
              }"
              no-data-text=""
              hide-no-data
              hide-details
              solo
              flat
            />
          </div>
          <v-divider class="my-4" />

          <!-- 点検日 -->
          <div class="px-0 px-sm-4 d-flex align-center">
            <div v-if="!$vuetify.breakpoint.xs" style="width: 100px">点検日</div>
            <DatePicker :date.sync="selectedDate" />
          </div>
          <v-divider class="my-4" />

          <!-- タグ -->
          <div class="px-0 px-sm-4 d-flex align-center">
            <div v-if="!$vuetify.breakpoint.xs" style="width: 100px; min-width: 100px">
              検索用タグ
              <span class="text-caption">（複数可）</span>
            </div>
            <v-autocomplete
              v-model="selectedSearchTags"
              :items="searchTags"
              item-text="name"
              return-object
              append-icon="mdi-plus-circle-outline"
              :label="$vuetify.breakpoint.xs ? '検索用タグ' : ''"
              :menu-props="{
                offsetY: true,
                transition: 'slide-y-transition',
              }"
              :search-input.sync="searchText"
              multiple
              no-data-text=""
              hide-no-data
              hide-details
              solo
              flat
              @input="searchText = ''"
            >
              <template #selection="{ item, index }">
                <v-chip
                  :color="item.color"
                  :dark="isDarkChip(item.color)"
                  close
                  @click:close="selectedSearchTags.splice(index, 1)"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
          <v-divider class="my-4" />

          <!-- コメント -->
          <div class="px-0 px-sm-4 d-flex align-center">
            <div v-if="!$vuetify.breakpoint.xs" style="width: 100px">コメント</div>
            <v-textarea
              v-model="comment"
              hide-details
              solo
              flat
              :label="$vuetify.breakpoint.xs ? 'コメント' : ''"
              no-resize
              :height="getFieldHeight"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row no-gutters justify="end">
      <ButtonSendResult
        :loading="sending"
        :isNormal="true"
        :disabled="sending || isDisabledButton || isChecked"
        :buttonText="isChecked ? '確認済' : ''"
        @click="sendCheckResult()"
      />
    </v-row>

    <DialogImg ref="dialogImg" />
    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import PDFView from "../organisms/PDFView";
import DatePicker from "../organisms/DatePicker";

export default {
  components: {
    PDFView,
    DatePicker,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    showIndex: 0,
    imgFiles: [],
    formTagName: "",
    formTags: [],
    selectedDate: "",
    searchTags: [],
    searchText: "",
    selectedSearchTags: [],
    comment: "",
    uid: "",
    isChecked: false,
    message: "",
    messageDialog: false,
    sending: false,
  }),
  created: async function () {
    this.$emit("created");
    this.logEvent("app_connect");

    this.selectedDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    this.loadList();
  },
  computed: {
    /**
     * テキストエリアの高さを取得
     * @return {number} 高さ
     */
    getFieldHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 556 : 523);
      return height <= 200 ? 200 : height;
    },

    /**
     * チップの文字色を背景色から計算
     * @param {string} 色（例：#4A148CFF）
     */
    isDarkChip() {
      return function (color) {
        if (!color) return false;
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
        const a = parseInt(color.substr(7, 2), 16);
        if (a == 0) return false;
        return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? true : false;
      };
    },

    /**
     * 送信ボタンの活性判定
     * @return {boolean} 判定結果
     */
    isDisabledButton() {
      if (this.imgFiles.length == 0) return true;
      if (this.formTagName == "") return true;
      return false;
    },
  },
  methods: {
    /**
     * DBからタグ一覧を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const items = await this.getQueryDoc({
        collection: "photoRecordTags",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "createdAt", directionStr: "asc" }],
      });
      if (items.length > 0) {
        this.formTags = items[0].formTags ? items[0].formTags : [];
        this.searchTags = items[0].searchTags ? items[0].searchTags : [];
      }
      this.loading = false;
    },

    /**
     * 画像ファイルを選択
     */
    async selectImg() {
      const file = this.$refs.fileInput.files[0];
      this.$refs.fileInput.value = "";
      this.imgFiles.push(await this.selectFile(file));
      this.showIndex = this.imgFiles.length - 1;
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.selectedDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const resultId = this.createDocId("photoRecordCheckResults");
      const sendData = {
        userUID: user.userUID,
        shopUID: shop.shopUID,
        formTagName: this.formTagName,
        imgFiles: [],
        searchTags: this.selectedSearchTags.map((e) => ({
          name: e.name,
          color: e.color || "#E0E0E0FF",
        })),
        comment: this.comment,
        registeredAt,
        sentAt: serverTimestamp,
        sender: user.name,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        createdAt: serverTimestamp,
        updatedAt: serverTimestamp,
      };

      // 画像をアップロード
      for (const [i, file] of Object.entries(this.imgFiles)) {
        const extension = file.url.startsWith("data:application/pdf") ? ".pdf" : ".jpg";
        const fileDate = this.selectedDate.replace(/\//g, "-");
        const sendFileName = `${resultId}_${i + 1}${extension}`;
        const path = `photoReportCheckFile/${shop.shopUID}/${fileDate}/${sendFileName}`;
        const uploadResult = await this.uploadFile({ path, file: file.compressedFile });

        // 送信失敗の場合
        if (uploadResult.status == "error") {
          const type =
            uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
          this.$refs.sendErrorDialog.open(type);
          this.sending = false;
          return;
        }

        sendData.imgFiles.push({ fileName: file.fileName, url: uploadResult.url });
      }

      // DB登録
      const result = await this.writeTransaction([
        {
          method: "set",
          collection: "photoRecordCheckResults",
          docId: resultId,
          data: sendData,
        },
      ]);

      // 登録失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 点検票初期化
      this.imgFiles = [];
      this.formTagName = "";
      this.selectedSearchTags = [];
      this.comment = "";

      this.message = "送信しました。";
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message });

      this.sending = false;
    },
  },
};
</script>

<style scoped>
.v-card--link:focus:before {
  opacity: 0;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 40px;
}
.theme--light.v-btn:hover::before {
  opacity: 0;
}
.theme--light.v-btn:focus::before {
  opacity: 0;
}

.icon-camera {
  position: absolute;
  top: 0;
  right: 48px;
  margin: 8px;
  z-index: 1;
  opacity: 0.9;
}

.icon-trash {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  z-index: 1;
  opacity: 0.9;
}

.fileName {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.9;
}

::v-deep .v-list-item {
  height: 36px;
  min-height: 36px;
}

::v-deep .v-list-item__action {
  margin-right: 20px !important;
}

::v-deep .v-list-item__content {
  padding: 0;
}

::v-deep .v-list-item__title {
  font-size: 14px;
}
</style>
